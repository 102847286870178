<template>
   <div class="col-12 px-0" ref="boleto">
      <div class="card mb-1">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ boleto.codigo == null || String(boleto.codigo).trim().length == 0 ? 'Sem código' : boleto.codigo }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span class="me-3" v-if="boleto.id == null || showEdit"><i class="far fa-hashtag font-9 me-1"></i>{{ boleto.id == null ? '?' : boleto.id }}</span>
                           <span>
                              <i class="far fa-copy font-9 me-1"></i>
                              <span>{{ boleto.numeroDaParcela == null || String(boleto.numeroDaParcela).trim().length == 0 ? '?' : boleto.numeroDaParcela }}</span>
                              <span> de {{ boleto.totalDeParcelas == null || String(boleto.totalDeParcelas).trim().length == 0 ? '?' : boleto.totalDeParcelas }}</span>
                           </span>
                           <span class="ms-3" v-if="boleto.id != null && !showEdit">
                              <span v-if="boleto.pago" class="text-green"><i class="far fa-check font-9 me-1"></i>Pago</span>
                              <span v-else class="text-yellow"><i class="far fa-exclamation-triangle font-8 me-1"></i>Pendente</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-calendar font-10 color-theme me-1" v-if="showEdit"></i>
                           <i class="far fa-calendar font-10 me-1" v-else :class="boleto.pago ? 'text-green' : 'text-yellow'"></i>
                           <strong class="subtitle me-1 d-none d-md-inline"> Vencimento:</strong>
                           <span>{{ boleto.dataDeVencimento == null ? 'Não definido' : isNaN(new Date(String(boleto.dataDeVencimento).replace(/-/g, "/"))) ? new Date(boleto.dataDeVencimento).toLocaleDateString('pt-br') : new Date(String(boleto.dataDeVencimento).replace(/-/g, "/")).toLocaleDateString('pt-br') }}</span>
                        </p>
                     </div>
                     <div class="col-6">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-money-bill-wave-alt font-10 color-theme me-1" v-if="showEdit"></i>
                           <i class="far fa-money-bill-wave-alt font-10 me-1" v-else :class="boleto.pago ? 'text-green' : 'text-yellow'"></i>
                           <strong class="subtitle me-1 d-none d-md-inline"> Valor:</strong>
                           <span><small>R$ </small>{{ parseFloat(boleto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme" v-if="boleto.id">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="boleto.manual && showEdit">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Boleto',
   props: ['boleto', 'index', 'showEdit'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.boleto)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>